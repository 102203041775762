var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vxe-table',{attrs:{"border":"","height":"600","scroll-y":{enabled: false},"span-method":_vm.rowspanMethod,"data":_vm.tableData}},[_c('vxe-column',{attrs:{"field":"name1","title":"功能模块"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('vxe-checkbox',{on:{"change":function($event){return _vm.check1ChangeEvent(row, row.check1)}},model:{value:(row.check1),callback:function ($$v) {_vm.$set(row, "check1", $$v)},expression:"row.check1"}},[_vm._v(" "+_vm._s(row.name1)+" ")])]}}])}),_c('vxe-column',{attrs:{"field":"name2","title":"详细功能"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('vxe-checkbox',{on:{"change":function($event){return _vm.check2ChangeEvent(row, row.check2)}},model:{value:(row.check2),callback:function ($$v) {_vm.$set(row, "check2", $$v)},expression:"row.check2"}},[_vm._v(" "+_vm._s(row.name2)+" ")])]}}])}),_c('vxe-column',{attrs:{"field":"name3","title":"权限类型"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('vxe-checkbox',{on:{"change":function($event){return _vm.check3ChangeEvent(row, row.check3)}},model:{value:(row.check3),callback:function ($$v) {_vm.$set(row, "check3", $$v)},expression:"row.check3"}},[_vm._v(" "+_vm._s(row.name3)+" ")])]}}])}),_c('vxe-column',{attrs:{"field":"name4","title":"权限列表"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('vxe-checkbox',{on:{"change":function($event){return _vm.check4ChangeEvent(row, row.check4)}},model:{value:(row.check4),callback:function ($$v) {_vm.$set(row, "check4", $$v)},expression:"row.check4"}},[_vm._v(" "+_vm._s(row.name4)+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }